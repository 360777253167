
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    const router = useRouter()
    const _isMobile = () => {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
    onMounted(() => {
      const navIndex = sessionStorage.getItem('navIndex')
      if (!navIndex) {
        sessionStorage.setItem('navIndex', String(0))
      }
      const nav = JSON.parse(sessionStorage.getItem('navIndex')!)
      if (_isMobile()) {
        router.replace(nav === 1 ? '/mb_digitalize' : nav === 2 ? '/mb_supply' : nav === 3 ? '/mb_cooperation' : nav === 4 ? '/mb_news' : nav === 5 ? '/mb_aboutus' : '/mb_home')
      }
    })
  }
})
